<template>
    <div class="commonAgreement_contaier">
        <div class="agreement_main">
            <h1><b class=""></b></h1>
            <p class=""><b class="">甲方：北京智慧医联科技有限公司</b></p>
            <p class="">注册地址：北京市东城区藏经馆胡同17号1幢1863室</p>
            <p class=""><b class="">乙方：{{ data.doctor_name }}</b></p>
            <p class="">身份证号码：{{ data.doctor_id_card_number }}</p>
            <p class=""><b class="">所在单位：{{ data.doctor_hospital }}</b></p>
            <p class=""><b class="">本服务协议(下称“本协议”)于<span class="agreement_underline">{{ $tools.getDate(data.meet_sign_time, "yyyy年MM月dd日") }}</span>由双方签署后生效（“生效日”）。</b>
            </p>
            <p class="">1、服务范围</p>
            <p class="">甲方有意聘请乙方且乙方同意接受聘请，为甲方提供<span class="agreement_underline">_医学策划及医学内容讲解_</span>服务。</p>
            <p class=""><b class="">2、服务报酬</b></p>
            <p class="">2.1在本协议有效期内，乙方向甲方提供服务，甲方向乙方支付服务报酬（下称“服务报酬”）甲方不得因为乙方损失的收入而支付给乙方报酬（例如手术费损失或误工损失）。</p>
            <p class="">2.2甲方以银行转账方式在每次服务完成后，30日内向乙方结算服务报酬<span class="agreement_underline">{{ data.project_amount }}</span>元整，为税后金额。乙方的收款账户信息为：
            </p>
            <div class="agreement_underline">户名：{{ data.doctor_name }}</div>
            <div class="agreement_underline">银行名：{{ data.doctor_bank }}</div>
            <div class="agreement_underline">账号：{{ data.doctor_bank_number }}</div>
            <p class=""><b class="">3、乙方陈述与保证</b></p>
            <p class="">3.1乙方在法律上有充分的权利、权力和权限签署和交付本协议及本协议中提及的该方为一方的所有协议和文件，以及遵守并履行本协议及该等协议和文件规定的义务；</p>
            <p class="">
                3.2乙方签署本协议和履行本协议项下的义务，将不会：(1)与乙方的职位或官方/政府职衔存在任何冲突；(2)违反任何其作为签约一方或受其约束一方的任何协议或其它法律文件，不需要在这些文件项下取得任何同意；(3)违反任何授权、判决、法令、命令或法律法规；
            </p>
            <p class="">3.3乙方已按照有关授权、判决、法令、命令或任何法律、法规、规章、规则或要求的规定通知其雇主或其他相关主体其将向甲方提供服务，并已就其签署和履行本协议取得了其雇主或其他相关主体的批准、许可或同意；
            </p>
            <p class="">
                3.4乙方保证按照一个具有同等资历、经验和技能的人所通常实践的技能和谨慎、勤勉的标准提供本协议项下之服务，根据甲方随时为其设定的具体服务内容，以及服务所须达到的目标、标准和要求，在甲方规定的时间以内保证质量地履行和完成有关服务；
            </p>
            <p class="">3.5乙方保证及时判断其是否能够依照甲方的要求妥善完成甲方设定的具体服务内容，并须立即通知甲方，以便甲方做出安排。</p>
            <p class="">4、对乙方信息的收集</p>
            <p class=""><b class="">4.1甲方收集乙方的本人身份证，银行卡复印件，以及手机号码，仅用于支付服务报酬以及办理相关税费代扣代缴；</b></p>
            <p class=""><b
                    class="">4.2甲方任用第三方进行乙方付款及相关税费代扣代缴，需要将乙方的身份信息以及电话号码告知第三方，甲方应与第三方签订保密协议，不得将乙方的身份信息以及电话号码披露给任何无关方，但是根据法律要求披露的除外；</b>
            </p>
            <p class="">4.3乙方签署本协议视为同意上述条款，不得在任何时候以不知情为由拒绝告知身份信息或电话号码，或使甲方承担诉讼风险。</p>
            <p class="">5、工作成功所有权</p>
            <p class=""><b
                    class="">鉴于在本协议项下所支付的款项，乙方因履行服务而取得的信息、数据、报告、文章、艺术品、理念、源代码、发明（统称“工作成果”），无论以何种形式，有形或无形的及所有工作成果的知识产权应归甲方独家所有</b>
            </p>
            <p class="">6、保密</p>
            <p class="">除由其它途径成为公开资料或法律规定必须予以披露的以外，未经对方同意任何一方不得向他人披露本协议内容或为讨论、签署、履行协议而向其雇员、代理人等提供的资料或信息</p>
            <p class="">7、终止</p>
            <p class=""><b class="">7.1经提前5个工作日书面通知另一方，任一方可终止本协议。</b></p>
            <p class="">7.2若乙方未履行及/或未完全履行其在本协议的责任和义务，或本协议下的陈述和保证未能满足或成为不真实、不准确或具有误导性的表述，则甲方可无须事先通知立即终止本协议并有权全部或部分扣减服务报酬。</p>
            <p class="">7.3本协议终止后，双方的权利和义务应终止，但第6条、第8条和第10条继续有效。</p>
            <p class="">8、违约责任</p>
            <p class="">
                8.1任何一方违反本协议项下的任何义务或本协议下的陈述和保证未能满足或成为不真实、不准确或具有误导性的表述（下称“违约方”），另一方（下称“守约方”）可向其发出书面通知，要求违约方在指定的合理期限内采取补救措施；如违约方未于上述期限内对该等违约行为采取补救措施，则守约方有权向违约方追索因违约方违约而给守约方造成的损失和损害。
            </p>
            <p class="">8.2一方未行使本协议项下的任何权利，不应构成其放弃任何该等权利。</p>
            <p class="">9、适用法律及争议解决</p>
            <p class="">
                本协议受中华人民共和国(不包括香港、澳门及台湾地区)法律管辖并根据中国法律解释。如出现任何由本协议引起或与本协议有关的争议，经双方协商后仍无法解决的，本协议任何一方有权向甲方所在地的有管辖权的人民法院提起诉讼。
            </p>
            <p class="">10、其他</p>
            <p class="">10.1除非经甲方事先书面同意和确认，乙方不得将本协议或本协议项下其任何权利或义务转让给任何其它第三方。</p>
            <p class="">10.2在本协议规定的任何条款与中国法律冲突或依中国法律成为无效或不合法时，这些条款不应影响本协议其他条款的效力和有效性。</p>
            <p class=""><b class="">10.3本协议任何条款的修订、修改或补充，须经甲方和乙方双方书面同意并签署方为有效。</b></p>
            <p class="">10.4本协议一式两份，双方各持一份，每份均以中文书写。</p>
            <p class=""><b class=""></b></p>
            <p class=""><b class=""></b></p>
        </div>
        <div class="agreement_sign_info">
            <div class="agreemtn_sign_them">
                <div class="agreement_sign_name"><span>甲方：</span>{{ data.agreement_company_name }}</div>
                <div class="agreement_sign_date">{{ $tools.getDate(data.meet_sign_time, "yyyy年MM月dd日") }}</div>
            </div>
            <div class="agreemtn_sign_owner">
                <div class="agreement_sign_name">
                    <span>乙方：</span>
                    <customButton @click="doSignName(0)" v-if="!(data.meet_sign_name || signData[0])">
                        <div>
                            <span>签名</span>
                        </div>
                    </customButton>
                    <img :src="data.meet_sign_name?.split(',')[0] || this.signData[0]" @click="doSignName(0)" v-else alt="">
                </div>
                <div class="agreement_sign_date">{{ $tools.getDate(data.meet_sign_time, "yyyy年MM月dd日") }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import customButton from "@/components/unit/customButton"
import nzh from "nzh"
export default {
    name: "commonAgreement",
    props: {
        agreementData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        agreementFields: {
            type: Array,
            default: () => {
                return []
            }
        },
        signData: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            showSignName: false,
            agreementHTML: '',
            data: {},
            config: {
                nullText: /<(div|p)\s*class=[\s\w\"]*><\/(div|p)>/g,
            },
        }
    },
    mounted() {
        // this.initData(this.agreementData,this.agreementFields)
        this.data = this.agreementData;
        this.$emit("initScroll")
    },
    components: {
        customButton,
    },
    methods: {
        initData(data, agreementFields) {
            this.data = data;
            let html = this.data.project_agreement?.replace(this.config.nullText, "") || ""
            html = html.replace(/{{<\/*[\w]+>/, "{{") //{{<span>current_day}} ==>{{current_day}}
            html = html.replace(/<\/*[\w]+>}}/, "}}") //{{current_day</span>}} ==>{{current_day}}
            for (let item of agreementFields) {
                let reg = new RegExp(`{{${item.field}}}`, "g")
                let val = this.data[item.field]
                let encodeS = nzh.cn.encodeS;
                if (item.use_format) {
                    try {
                        val = eval(`${item.use_format}()`)
                    } catch (err) {
                        console.log(err)
                    }
                }
                html = html.replace(reg, val)
            }
            this.agreementHTML = html;
            this.$emit("initScroll")
        },
        verifyData() {
            if (!this.signData[0]) {
                this.$tips.error({ text: "请先签名" })
                return false;
            }
            return true;
        },
        doSignName(index) {
            this.$emit("doSignName", index)
        },
    }
}
</script>
<style>.commonAgreement_contaier {
    width: 100%;
    height: 100%;
}

.agreement_main {
    height: 100%;
    overflow: hidden;
}

.agreement_main div.agreement_underline {
    border-bottom: 1px solid #000;
}

.agreement_main h1 {
    width: 100%;
    text-align: center;
    font-size: 6vw;
}

.agreement_main h3 {
    width: 100%;
    font-size: 4vw;
    margin: 3vw 0;
}

.agreement_main b {
    font-weight: 500;
}

.agreement_main p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.agreement_main p,
.agreement_main div {
    line-height: 6.8vw;
    width: 100%;
    /* text-indent: 8vw;; */
}

.agreement_sign_info {
    display: flex;
}

.agreement_sign_info>div {
    flex: 1 0 0;
    margin-top: 6vw;
}

.agreement_sign_name {
    height: 16vw;
    display: flex;
    align-items: center;
}

.agreement_sign_name span {
    white-space: nowrap;
}

.agreement_sign_name img {
    width: 24vw;
    height: auto;
}</style>